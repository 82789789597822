class Siteheader {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-siteheader',
            hiddenClassname: 'siteheader--hidden',
            toggleAttr: 'toggle'
        };

        this.settings = Object.assign({}, defaults, options);

        this.$siteheader = element;
        this.$siteheaderToggles = this.$siteheader.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.toggleAttr + '"]');
        this.eventScroller = window.eventScroller;
    }

    initialize () {
        this.handleVisibility();
        this.setEvents();
    }

    setEvents () {
        this.eventScroller.customFunctions.push(() => {
            this.handleVisibility();
        });

        this.$siteheaderToggles.forEach($toggle => {
            $toggle.addEventListener('change', () => {
                this.unCheckToggles($toggle);
            });
        });
    }

    handleVisibility () {
        const siteheaderHeight = this.$siteheader.offsetHeight;

        if (window.scrollPosition > siteheaderHeight) {
            if (window.scrollDirection === 'down') {
                this.toggleHeader(false);
            } else {
                this.toggleHeader(true);
            }
        } else {
            this.toggleHeader(true);
        }
    }

    toggleHeader (visible) {
        if (visible === true) {
            if (this.$siteheader.classList.contains(this.settings.hiddenClassname)) {
                this.$siteheader.classList.remove(this.settings.hiddenClassname);
            }
        } else {
            if (!this.$siteheader.classList.contains(this.settings.hiddenClassname)) {
                this.$siteheader.classList.add(this.settings.hiddenClassname);
            }
        }
    }

    unCheckToggles ($activeToggle) {
        this.$siteheaderToggles.forEach($toggle => {
            if ($toggle !== $activeToggle) {
                $toggle.checked = false;
            }
        });
    }
}

export default Siteheader;
